export enum PageName {
  Main = 'main',
  Create = 'create',
  DeprecatedCreate = 'deprecated_create',
  Profile = 'profile',
  Orders = 'orders',
  Order = 'order',
  Album = 'album',
  Albums = 'albums',
  OAuthFacebook = 'oauth_facebook',
  PaymentSuccess = 'payment_success',
  PaymentFailed = 'payment_failed',
  Contacts = 'contacts',
  Faq = 'faq',
  NotFound = 'not_found',
  TermsAndConditions = 'terms_and_conditions',
}

export enum ModalName {
  Login = 'login',
  NotEnoughPhotos = 'not_enough_photos',
  MaxPhotos = 'max_photos',
  AccountDelete = 'account_delete',
}

export enum TopMenuItem {
  NewAlbum = 'new_album',
  Profile = 'profile',
  Orders = 'orders',
  Albums = 'albums',
  Logout = 'logout',
}
